//
// Pricing card
//

.card-pricing {
  .card-header {
    padding-top: 0;
    padding-bottom: 0;

    h6 {
      font-weight: 900;
    }
  }
  .list-unstyled li {
    padding: 0.5rem 0;
    color: $gray-600;
  }

  &.popular {
    z-index: 1;
    border: 3px solid theme-color("primary") !important;
  }

  &[data-icon="big-icon"] {
    .card-header {
      border-bottom: none;
      padding-bottom: 0;

      .icon {
        width: 5rem;
        height: 5rem;
        margin: 15% auto 0;

        i {
          font-size: 4rem;
        }
      }

      p {
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    .card-body {
      padding-top: 0;

      .price {
        position: relative;

        .currency {
          position: absolute;
          bottom: 25px;
          left: 5px;
          font-size: 32px;
        }
        .amount {
          font-size: 5rem;
          margin-left: 25px;
        }
        .frequency {
        }
      }

      .list-unstyled {
        li p {
          margin-bottom: 0;
        }
      }
    }

    .card-footer {
      border-top: none;
      padding-top: 0;
    }
  }

  &:not([data-icon="big-icon"]) {
    .icon i:before {
      font-size: 12px;
    }
  }

  &.card-background {
    background-position: 50%;
    background-size: cover;

    .card-body {
      position: relative;
      z-index: 2;
      min-height: 280px;
      max-width: 440px;

      ul {
        list-style: none;
        padding: 0;
        max-width: 240px;
        margin: 10px auto;

        li {
          color: $default;
          border-color: hsla(0, 0%, 100%, 0.3);
          text-align: center;
          padding: 12px 0;
          border-bottom: 1px solid hsla(0, 0%, 60%, 0.3);

          &:last-child {
            border: 0;
          }
        }
      }
    }

    &[data-background="image"] {
      background-size: contain;
      background-repeat: no-repeat;
      background-position-y: top;

      ul li {
        color: $white;
        padding: 10px 0;
      }
      &:after {
        display: none;
      }

      .card-body {
        padding-top: 90px;
        padding-bottom: 20px;

        .category {
          margin-bottom: 0;
        }
      }
    }

    &:after {
      display: none;
    }
  }

  &.card-coin {
    margin-top: 75px;

    .card-header {
      margin: -50px auto 0px;
      border-bottom: none;
      background: transparent;
    }

    .card-body h5 {
      font-weight: 600;
    }

    img {
      width: 100px;
      border-radius: 50%;
    }
    hr {
      width: 20%;
      margin: 15px auto;
      height: 1px;
      border-radius: 3px;
    }
    .list-group {
      margin: 0 auto;
      text-align: center;

      .list-group-item {
        background-color: transparent;
        border: none;
        padding: 0.4rem 1rem;
      }
    }

    .card-footer {
      padding-bottom: 15px;
      border-top: none;
    }
  }
}

@include media-breakpoint-up(md) {
  .card-pricing.zoom-in {
    z-index: 1;
    transform: scale(1.1);
  }
}
