.table-pricing {
  .desc {
    margin-bottom: 10px;
  }

  td {
    border-top: none;
  }
  .background {
    width: 170px;
  }
}

.tables-1 {
  .table-pricing {
    thead tr th p {
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }
}

.tables-2 {
  .card {
    .card-body {
      padding: 0;
    }
  }
  .table-pricing {
    margin-bottom: 0;
    th {
      border-bottom: none;
      border-top: none;
      padding-bottom: 40px;

      &:last-child {
        border: none;
      }
    }

    tr {
      td {
        border-bottom: 1px solid rgba(94, 114, 228, 0.24);
        padding: 15px 30px;
      }

      &.bg-primary {
        td {
          border: none;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    .description {
      text-transform: none !important;
    }
  }

  thead th {
    position: relative;
  }
}
@include media-breakpoint-down(sm) {
  .tables-2 .table-pricing .btn {
    padding: 5px 0;
  }
}
