// General styles

.badge {
  text-transform: $badge-text-transfom;

  a {
    color: $white;
  }
}

// Size variations

.badge-md {
  padding: 0.65em 1em;
}

.badge-lg {
  padding: 0.85em 1.375em;
}

// Multiple inline badges

.badge-inline {
  margin-right: 0.625rem;

  + span {
    top: 2px;
    position: relative;

    > a {
      text-decoration: underline;
    }
  }
}

// Color fixes

.badge-default {
  color: $white;
}

.badge-secondary {
  background-color: theme-color("secondary");
  color: color-yiq(theme-color("secondary"));
}

//
// Dot badge
//

// General styles

.badge-dot {
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  text-transform: none;

  strong {
    color: $gray-800;
  }

  i {
    display: inline-block;
    vertical-align: middle;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    margin-right: 0.375rem;
  }

  &.badge-md {
    i {
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  &.badge-lg {
    i {
      width: 0.625rem;
      height: 0.625rem;
    }
  }
}

//
// Circle badge
//

// General styles

.badge-circle {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0 !important;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.75rem;
  font-weight: 600;

  &.badge-md {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.badge-lg {
    width: 2rem;
    height: 2rem;
  }
}

//
// Badge floating
//

.btn {
  .badge-floating {
    position: absolute;
    right: -15px;
    top: -50%;
    border: 3px solid;

    &.badge:not(.badge-circle) {
      transform: translate(-20%, 50%);
    }
  }
}
