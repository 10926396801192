.social-line-big-icons {
  [class*="col-"] {
    border-right: 1px solid $secondary;

    &:last-child {
      border: 0;
    }
  }

  .btn {
    margin: 0;
    width: 100%;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    height: auto;

    i {
      font-size: 25px;
    }
  }

  .title {
    font-size: 2rem;
    text-transform: lowercase;
    font-weight: bolder;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .subtitle {
    font-size: 8px;
    margin-top: -7px;
  }
}

.container-fluid {
  .social-line-big-icons {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.social-line {
  padding: 0.85rem 0;
}

.subscribe-line {
  padding: 15px * 2 0;

  .card {
    margin-top: 54px;

    .card-block {
      min-height: auto;
    }

    .input-group {
      margin-bottom: 0px;
    }
  }

  .btn {
    margin: 0;
  }
}

[data-background-color] {
  .social-line {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: initial;
    }

    .btn.btn-simple {
      border: 0;
    }
  }
}
