.presentation-page {
  .section-hero {
    .page-header-image {
      background-repeat: no-repeat;
      background-size: contain !important;
      background-position: right !important;
    }
  }

  section {
    padding: 80px 0;
  }

  .section-basic-components {
    overflow: hidden;

    .image-container {
      .table-img,
      .share-btn-img,
      .coloured-card-btn-img,
      .coloured-card-img,
      .social-img,
      .linkedin-btn-img {
        position: absolute;
      }

      .table-img {
        left: 65%;
        top: 23%;
        height: 250px;
        border-radius: 3px;
      }

      .share-btn-img {
        left: 83%;
        top: 95%;
        height: 45px;
        border-radius: 10px;
      }

      .coloured-card-img {
        left: 50%;
        top: 0%;
        height: 145px;
        border-radius: 3px;
      }

      .coloured-card-btn-img {
        left: -3%;
        top: 42%;
        height: 200px;
        border-radius: 3px;
      }

      .social-img {
        left: -4%;
        top: 95%;
        height: 57px;
      }

      .linkedin-btn-img {
        left: 3%;
        top: -3%;
        height: 40px;
        border-radius: 10px;
      }
    }
  }

  .section-cards {
    .glide--carousel .glide__slide {
      width: 300px !important;
      img {
        width: 100%;
        margin: 50px auto;
      }

      .avatar {
        width: auto;
      }
    }
  }

  .section-sections {
    .section-cols {
      img {
        width: 100%;
      }
    }
  }

  .section-examples {
    img {
      width: 100%;
    }
  }

  .section-patterns {
    max-height: 999px;

    .align {
      margin-top: 15em;
    }

    .pattern-1 {
      position: relative;
      left: -2em;
      top: 2em;
    }
    .pattern-2 {
      position: relative;
      left: 22em;
      top: -21em;
    }
    .pattern-3 {
      position: relative;
      left: -5em;
      top: -15em;
    }
    .pattern-4 {
      position: relative;
      left: 22em;
      top: -38em;
    }
  }

  .section-free-demo {
    position: relative;
    overflow: hidden;

    .github-buttons {
      > .btn {
        float: left;
        margin-right: 15px;
      }

      .github-button {
        float: left;
        margin-top: 8px;
      }
    }

    .github-background-container {
      position: absolute;
      right: -420px;
      top: -50px;

      i {
        font-size: 810px;
        opacity: 0.1;
      }
    }
  }

  .section-icons {
    .icons-nucleo {
      width: 100%;
      height: 100%;
      position: relative;
      top: -48px;

      i {
        position: absolute;
      }

      .first-left-icon {
        top: -5%;
        left: 10%;
        font-size: 65px;
      }
      .second-left-icon {
        top: 0%;
        left: 45%;
        font-size: 35px;
      }
      .third-left-icon {
        top: 30%;
        left: 65%;
        font-size: 25px;
      }
      .fourth-left-icon {
        top: 70%;
        left: 10%;
        font-size: 50px;
      }
      .fifth-left-icon {
        top: 45%;
        left: 30%;
        font-size: 40px;
      }
      .sixth-left-icon {
        top: 120%;
        left: 35%;
        font-size: 40px;
      }
      .seventh-left-icon {
        top: 35%;
        left: 85%;
        font-size: 45px;
      }
      .eighth-left-icon {
        top: 90%;
        left: 55%;
        font-size: 30px;
      }
      .ninth-left-icon {
        top: 40%;
        left: 5%;
        font-size: 40px;
      }
      .tenth-left-icon {
        top: 75%;
        left: 80%;
        font-size: 35px;
      }
      .first-right-icon {
        top: 0;
        right: 10%;
        font-size: 65px;
      }
      .second-right-icon {
        top: 0%;
        right: 45%;
        font-size: 35px;
      }
      .third-right-icon {
        top: 30%;
        right: 65%;
        font-size: 25px;
      }
      .fourth-right-icon {
        top: 70%;
        right: 10%;
        font-size: 50px;
      }
      .fifth-right-icon {
        top: 45%;
        right: 30%;
        font-size: 40px;
      }
      .sixth-right-icon {
        top: 120%;
        right: 35%;
        font-size: 35px;
      }
      .seventh-right-icon {
        top: 35%;
        right: 88%;
        font-size: 30px;
      }
      .eighth-right-icon {
        top: 90%;
        right: 55%;
        font-size: 35px;
      }
      .ninth-right-icon {
        top: 40%;
        right: 5%;
        font-size: 40px;
      }
      .tenth-right-icon {
        top: 75%;
        right: 80%;
        font-size: 35px;
      }
    }
  }

  .section-features {
    .info {
      padding-top: 0 !important;
    }
  }

  .section-pricing {
    .our-clients {
      [class^="col-"] {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  .section-testimonials {
    padding-bottom: 0;
    position: relative;

    .carousel {
      .card {
        margin: 40px auto;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      color: $black;
      top: 15em !important;
    }

    .carousel-indicators button {
      background-color: $primary;
    }
    .carousel-indicators li {
      background-color: $primary;
    }

    .testimonials-people {
      margin-top: -100px;
    }
  }

  .section-content {
    @include media-breakpoint-up(md) {
      .image-container {
        height: 400px;
      }
      .image-container img {
        position: absolute;
        width: 100%;
        max-width: 380px;
      }
      .image-container .img-blog {
        z-index: 4;
        left: 100px;
        top: 20px;
      }
      .image-container .img-comments {
        z-index: 3;
        left: -100px;
        top: -40px;
      }
    }

    @include media-breakpoint-down(md) {
      .image-container {
        .img-comments {
          left: 100px;
        }
        .img-blog {
          left: 200px;
        }
      }
    }
  }

  .card-pricing.card-background {
    .card-body ul {
      max-width: 250px;
    }
  }
}

@include media-breakpoint-down(md) {
  .section-cards {
    .presentation-cards {
      .glide__arrow--left {
        left: 40%;
      }
      .glide__arrow--right {
        right: 40%;
      }
    }
  }
}

@keyframes floating {
  from {
    -webkit-transform: translate(50px, 0px);
    -ms-transform: translate(50px, 0px);
    transform: translate(50px, 0px);
  }
  65% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(50px, 0px);
    -ms-transform: translate(50px, 0px);
    transform: translate(50px, 0px);
  }
}

// animation - Floating basic components

.table-img,
.coloured-card-img,
.coloured-card-btn-img,
.linkedin-btn-img,
.share-btn-img,
.social-img {
  animation-name: floating;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.table-img {
  -webkit-animation-duration: 14s;
  animation-duration: 14s;
}

.coloured-card-btn-img {
  -webkit-animation-duration: 18s;
  animation-duration: 18s;
}

.linkedin-btn-img {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
}

.share-btn-img {
  -webkit-animation-duration: 11s;
  animation-duration: 11s;
}

.social-img {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
}

@include media-breakpoint-down(md) {
  .presentation-page {
    .section-hero {
      .page-header-image {
        background-size: cover !important;
        background-position: 50% !important;
        opacity: 0.1;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .section-info {
    [class^="col-"]:nth-of-type(2) {
      .info {
        margin-top: 0 !important;
      }
    }
  }
  .section-patterns {
    max-height: 100% !important;

    .pattern-2,
    .pattern-4 {
      left: 10em !important;
    }
  }

  .section-icons {
    .icons-nucleo {
      display: none;
    }
  }

  .presentation-page {
    .section-cards {
      .glide--carousel {
        .glide__track {
          overflow-y: visible !important;
        }

        .glide__slide {
          width: 120px !important;
          position: relative;
          right: 7%;

          img {
            height: 150px !important;
            width: 100px !important;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .glide__arrows {
          margin-top: 30px;

          .glide__arrow--left {
            left: 30%;
          }
          .glide__arrow--right {
            right: 30%;
          }
        }
      }
    }
  }

  .testimonial-glide.glide--swipeable {
    &.glide--carousel {
      .glide__track {
        overflow-y: visible !important;
      }

      .glide__slide {
        width: 100px !important;
        position: relative;
        right: 7%;

        .info {
          overflow: hidden;
          height: 150px !important;
          width: 100px !important;
        }
      }

      .glide__arrows {
        margin-top: 80px;

        .glide__arrow--left {
          left: 30% !important;
        }
        .glide__arrow--right {
          right: 30% !important;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .section-patterns {
    max-height: 100% !important;
  }
  .pattern-1 {
    left: auto !important;
    top: 3em !important;
  }
  .pattern-2 {
    top: auto !important;
  }
  .pattern-3 {
    left: 1em !important;
    top: auto !important;
  }
  .pattern-4 {
    top: auto !important;
  }

  .presentation-page {
    .section-cards {
      .glide--carousel {
        .glide__track {
          overflow-y: visible !important;
        }

        .glide__slide {
          width: 170px !important;

          img {
            width: 170px !important;
            height: 260px !important;
          }
        }

        .glide__arrows {
          margin-top: 80px;
        }
      }
    }
  }

  .testimonial-glide {
    &.glide--carousel {
      .glide__track {
        overflow-y: visible !important;
      }

      .glide__slide {
        width: 170px !important;

        .info {
          overflow: hidden;
          width: 170px !important;
          height: 260px !important;
        }
      }

      .glide__arrows {
        margin-top: 80px;

        .glide__arrow--left {
          left: 40%;
        }
        .glide__arrow--right {
          right: 40%;
        }
      }
    }
  }

  .testimonials-3 {
    .positioned {
      position: relative;
    }
  }
}
