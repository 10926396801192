/*!

 =========================================================
 * Argon Desing System PRO Angular - v1.4.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/argon-design-system-pro-angular
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Bootstrap Functions
@import "bootstrap/functions";

// Custom Variables
@import "custom/variables";

// Theme Variables
@import "theme/variables-pro";

// Bootstrap Core
@import "bootstrap/bootstrap";

// Theme Core
@import "theme/theme-pro";

// Custom Variables
@import "custom/styles";

// Angular diferences
@import "angular/angular-differences";
