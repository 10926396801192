header {
  position: relative;
}

.page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  .carousel .carousel-indicators {
    bottom: 60px;
  }

  > .container,
  > .container-fluid {
    padding-top: 12vh;
    padding-bottom: 40px;
  }

  .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  & > .content {
    margin-top: 11%;
    text-align: center;
    margin-bottom: 50px;
  }

  .signup-page & {
    max-height: fit-content;
  }

  &.header-filter {
    position: relative;

    .container {
      z-index: 2;
      position: relative;
    }

    &:before {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: $white;
    padding: 0 15px;
    width: 100%;

    &.brand {
      width: 47%;
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .container {
    height: 100%;
    z-index: 1;
  }

  .category,
  .description {
    color: $secondary;
  }

  &.page-header-small {
    min-height: 60vh;
    max-height: 440px;
  }

  &.page-header-mini {
    min-height: 40vh;
    max-height: 340px;
  }

  .title {
    margin-bottom: 15px;
  }
  .title + h4 {
    margin-top: 10px;
  }

  &.header-filter:after {
    background: rgba(0, 0, 0, 0.5);
  }
}

.clear-filter {
  &:after,
  &:before {
    display: none;
  }
}

// header 1

.header-1 {
  .iframe-container img {
    border-radius: 10px;
  }

  .page-header-image {
    position: absolute;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }
}

// header 2

.header-2 {
  .page-header-image {
    opacity: 0.4;
  }

  .custom-control.custom-checkbox {
    padding-left: 0.8rem !important;
  }
}

.page-header {
  .floating-box {
    margin-top: 10em;
    border-radius: $border-radius;
    .box {
      margin-top: -40px;
    }
    .lead {
      margin-top: 0;
    }
  }
}

// header 3

.header-3 {
  overflow: hidden;

  .positioned {
    position: absolute;
    top: 11%;
    left: 4%;
    z-index: 2;

    .title {
      margin-left: 108px;
      text-align: left;
    }

    .info {
      padding: 0;

      .icon {
        margin: 13px 15px 0 0;

        i {
          font-size: 1em;
        }
      }
    }

    .title,
    .description,
    .btn {
      z-index: 1;
    }
  }

  .slick-slider {
    .slick-arrow {
      margin-top: 20px;
      padding: 10px;
    }
  }

  .slick-track {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .slick-slide {
    display: none;
    float: left;
    margin: 0 10px;
    margin-top: -20px;
    min-height: 1px;
    opacity: 0.1;
    transition: all 300ms ease 0s;

    &.slick-current.slick-active {
      opacity: 1;
      transition: all 300ms ease 0s;
      -webkit-transition: all 200ms ease-in;
      -webkit-transform: scale(1.2);
      -ms-transition: all 200ms ease-in;
      -ms-transform: scale(1.2);
      -moz-transition: all 200ms ease-in;
      -moz-transform: scale(1.2);
      transition: all 200ms ease-in;
      transform: scale(1.2);
    }

    img {
      width: 400px;
      margin-bottom: 30px;
    }
  }
}

// header 4

// HEADER 4

.header-4 {
  .navbar {
    z-index: 2;
    position: absolute;
  }

  .header-video {
    overflow: hidden !important;

    video {
      position: absolute;
      top: 35%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      z-index: 0;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .header-video .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $primary;
    opacity: 0.3;
    z-index: 1;
  }

  .video-text {
    font-size: 5em;
    color: $white;
  }
}

// header 5

.header-5 {
  .page-header-image {
    width: 50% !important;
    right: 0;
  }

  .title {
    margin-bottom: 25px;
  }
  .description {
    margin-bottom: 35px;
    margin-top: 25px;
  }

  .row-input {
    margin-bottom: 35px;
  }

  .hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
  }
}

.sections-page {
  .header-1 .navbar {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .header-3 {
    .page-header {
      min-height: 135vh;
    }

    .glide--carousel {
      margin-top: 5em;

      .glide__arrow--left {
        left: 45%;
      }
      .glide__arrow--right {
        right: 40%;
      }

      .glide__slides .glide__slide {
        opacity: 0.04;
        position: relative;
        right: 7%;

        &.glide__slide--active {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    .positioned {
      left: 0;
      top: 0;
      position: relative;
    }
  }
}

@include media-breakpoint-down(xs) {
  .page-header {
    .logo {
      text-align: center;
      margin-top: 50px;
    }
  }

  .header-1 {
    .iframe-container {
      margin-top: 5vh;
      text-align: center;
    }
  }

  .header-2 {
    .floating-box {
      margin-top: 4em;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .header-3 {
    a.btn {
      margin-top: 30px;
    }
    .description {
      margin-top: 20px;
    }

    div.positioned {
      margin-top: 0;
    }

    .page-header .content-center img {
      height: 150px !important;
      width: 100px !important;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
    }

    .title {
      font-size: 27px;
      margin-top: 15vh;
    }

    .glide--carousel {
      margin-top: 1em;

      .glide__arrow--left {
        left: 30%;
      }

      .glide__arrow--right {
        right: 30%;
      }

      .glide__arrows {
        margin-bottom: 5em;
      }
    }
    .positioned {
      left: 0;
    }
  }

  .header-5 {
    .btn {
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-down(md) {
  .header-1 {
    .page-header {
      .page-header-image {
        background-size: cover;
        opacity: 0.1;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .header-3 {
    .page-header {
      min-height: 150vh;
    }
    .glide--carousel {
      margin-top: 0;
    }
  }

  .header-6 {
    .page-header {
      min-height: 115vh;
    }
  }

  .card-background.card-grande .card-body {
    height: auto;
  }
}
