// multilevel dropdown

.dropdown.show {
  .dropdown-menu.show {
    .dropdown-item.dropdown-toggle + .dropdown-menu {
      &.show {
        left: 100% !important;
        margin-top: 0 !important;

        li.show {
          .dropdown-menu.show {
            left: 100% !important;
          }
        }
      }
    }

    .dropdown-menu.show {
      top: 70% !important;

      .dropdown-menu:first-of-type.show {
        top: 60% !important;
      }
      .dropdown-menu:nth-of-type(2).show {
        top: 83% !important;
      }
    }

    .dropdown-item.dropdown-toggle.open {
      &:after {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
    }
  }
}

.dropdown,
.dropup {
  .dropdown-item {
    &.dropdown-toggle:after {
      position: relative;
      float: right;
      top: 9px;
      margin-left: 0;
    }
    &:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      border-radius: 0.3rem;
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
      transition: all 0.4s ease;
      background: $white;
    }
  }
}

// Dropdown Animation

.dropdown {
  .dropdown-menu {
    -webkit-animation: fpFadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.3s ease;
    &:before {
      background: $white;
      box-shadow: none;
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      left: 5px;
      position: absolute;
      bottom: 100%;
      transform: rotate(-45deg) translateY(1rem);
      z-index: -5;
      border-radius: 0.2rem;
    }
  }

  &.show {
    .dropdown-menu {
      &.show {
        margin-top: 10px;
        bottom: unset !important;
        top: 100% !important;
        -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
        animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
        transform: none !important;
        transition: $transition-collapse;
      }
    }
  }
}

.dropup {
  .dropdown-menu {
    -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.3s ease;
    &:before {
      background: $white;
      box-shadow: none;
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      left: 5px;
      position: absolute;
      bottom: 7px;
      transform: rotate(-45deg) translateY(1rem);
      z-index: -5;
      border-radius: 0.2rem;
    }
  }

  &.show {
    .dropdown-menu {
      &.show {
        bottom: 100% !important;
        top: unset !important;
        -webkit-animation: fpFadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
        animation: fpFadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
        transform: none !important;
        margin-bottom: 10px;
        transition: all 0.3s ease;
      }
    }
  }
}

.dropdown-divider {
  margin: 0.2rem 0 !important;
}

.dropdown-toggle:after {
  transition: all 0.3s ease !important;
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;

  &:before {
    right: 20px;
    left: auto !important;
  }
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fpFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
