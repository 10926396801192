// Avatar size variations

.avatar-xl {
  width: 74px;
  height: 74px;
}

.avatar-lg {
  width: 58px;
  height: 58px;
  font-size: $font-size-sm;
}

.avatar-sm {
  width: 36px;
  height: 36px;
  font-size: $font-size-sm;
}

.avatar-xs {
  width: 24px;
  height: 24px;
  font-size: $font-size-xs;
}
